<style scoped>
.big-imgs {
    width: 100% !important;
}

.small-imgs {
    width: 33%;
    padding-left: 5px;
}

.border-tab {
    border-bottom: 2px solid #C89835 !important;
}


.carousel-container {
    position: relative;
    width: 100%;
}

.carousel-image {
    width: 100%;
    object-fit: cover;
}

.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

.carousel-control.left {
    left: 10px;
}

.carousel-control.right {
    right: 10px;
}

.carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.carousel-indicators span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: grey;
    border-radius: 50%;
    cursor: pointer;
}

.carousel-indicators .active {
    background-color: black;
}


.border-tab-line1 {
    border-right:1px solid rgb(187, 187, 187,0.5);
}
.border-tab-line2 {
    border-right:1px solid rgb(187, 187, 187,0.5);
}
.border-tab-line3 {
    border-top: none;
}
@media (max-width: 992px) {
    .border-tab-line2 {
        border-right: none !important;
    }
    .border-tab-line3 {
        border-top:1px solid rgb(187, 187, 187,0.5) !important;
    }
}

.position-close-btn {
    top: 20px;
    right: 20px;
    position: fixed;
    
}


#popupToggle {
    display: none;
}

/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* Popup */
.popup {
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    text-align: center;
}
        
/* Close Button */
.close-btn {
    display: inline-block;
    margin-top: 15px;
    padding: 8px 15px;
    background: #C89835;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
}

/* Show popup when checkbox is checked */
#popupToggle:checked + .overlay {
    display: flex;
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
</style>

<template>
    <div class="h-5" style="height: 10px"></div>

    <h4 class="p-3 m-0">{{ this.land_title }}</h4>

    <!-- image -->
    <div class="p-3 d-flex">
        <div class="d-flex" style="cursor:pointer; height: 300px; width: 300vh; overflow: scroll;">
            <div class="d-flex" v-for="(item, index) in this.image_property.slice(0, 12)" :key="index">
                <div style="width: 500px; padding-left: 10px; cursor: pointer" onclick="document.getElementById('popupToggle').click()">
                    <img class="p-0 img-fluid for-light small-imgs" style="height: 100%; width: 100%; border-radius: 10px;" :src="item.image" />
                </div>
            </div>
        </div>
    </div>

    <!-- details -->
    <div class="p-3 row">
        <div class="px-1 col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">

            <!-- LEFT SIDE (HOUSE OVERVIEW)-->
            <div class="bg-white p-3 rounded">
                <div class="d-flex" style="justify-content: space-between;">
                    <h5>Overview</h5>
                    <div v-if="this.soldListing == '1'">
                        <div class="p-1" style="text-align: center; width: 150px; border-radius: 10px; background-color: #C89835; color:#FEFEFE">Sold</div>
                    </div>
                    <div><span style="font-weight:500">Land-ID-{{ this.land_id }}</span></div>
                </div>
                <div class="py-1"></div>
                <div style="border-bottom: 1px solid #CCCCCC"></div>
                <div class="row">
                    <div class="border-tab-line1 p-3 d-flex col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                    style="justify-content: center; align-items: center; flex-direction: column">
                        <div style="text-align: center;">
                            <h4 style="color: #6C7592; font-size:0.8rem">{{ capitalizeFirstLetter(this.land_zoning) }}</h4>
                            <div style="font-size:0.7rem;">Land Zoning</div>
                        </div>
                    </div>
                    <div class="border-tab-line2 p-3 d-flex col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                    style="justify-content: center; align-items: center; flex-direction: column">
                        <div style="text-align: center;">
                            <h4 style="color: #6C7592; font-size:0.8rem">{{ capitalizeFirstLetter(convertType(this.land_type)) }}</h4>
                            <div style="font-size:0.7rem;">Land Type</div>
                        </div>
                    </div>
                    <div class="border-tab-line1 border-tab-line3 p-3 d-flex col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                    style="justify-content: center; align-items: center; flex-direction: column">
                        <div style="text-align: center;">
                            <h4 style="color: #6C7592; font-size:0.8rem">{{ capitalizeFirstLetter(convertStatus(this.land_status)) }}</h4>
                            <div style="font-size:0.7rem;">Land Status</div>
                        </div>
                    </div>
                    <div class="border-tab-line3 p-3 d-flex col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3" 
                    style="justify-content: center; flex-direction: column; align-items: center;">
                        <div style="text-align: center;">
                            <h4 style="color:#6C7592; font-size:0.8rem;">{{ this.size_of_land }}</h4>
                            <div style="font-size:0.7rem">{{ capitalizeFirstLetter(this.size_of_land_unit) }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-2"></div>

            <!-- detail -->
            <div class="bg-white rounded ">
                <div class="info">
                    <div data-intro="This is the your details" id="info-bar-tour" style=" overflow: hidden;">
                        <div class="d-flex row" style="align-items: center;">

                            <div class="p-0 col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style="cursor: pointer;">
                                <div :class="{ 'border-tab': tab1 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('description')">
                                    <h6 class="m-0">Description</h6>
                                </div>
                            </div>

                            <div class="p-0 col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style="cursor: pointer;">
                                <div :class="{ 'border-tab': tab2 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('details')">
                                    <h6 class="m-0">Details</h6>
                                </div>
                            </div>

                            <div class="p-0 col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style="cursor: pointer;">
                                <div :class="{ 'border-tab': tab3 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('location')">
                                    <h6 class="m-0">About Location</h6>
                                </div>
                            </div>

                            <div class="p-0 col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style="cursor: pointer;">
                                <div :class="{ 'border-tab': tab4 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('share')">
                                    <h6 class="m-0">Share</h6>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="this.tab1" class="p-3">
                        <div style="font-size: 0.9rem; padding-bottom:20px" :style="{ whiteSpace: 'pre-line' }">
                            {{ this.description }}
                        </div>
                    </div>
                    
                    <div v-if="this.tab2" class="p-3">
                        <h6>Topography:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            {{ this.land_topography ? capitalizeFirstLetter(this.land_topography) : 'Not Available' }}
                        </div>

                        <h6>Category:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            {{ this.land_category ? convertCategory(this.land_category) : 'Not Available' }}
                        </div>

                        <h6>Sales Type:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            {{ this.land_sales_type ? convertSalesType(this.land_sales_type) : 'Not Available' }}
                        </div>
                        
                        <h6>Price:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            RM {{ parseFloat(this.land_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                        </div>
                        
                        <h6>Price per {{ this.price_land_size_unit }}:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">RM {{ parseFloat(this.price_land_size_amount).toFixed(2) }}</div>
                    </div>
                    
                    <div v-if="this.tab3" class="p-3">
                        <h6>Address:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            {{ this.property_address ? this.property_address : 'Not Available' }}
                        </div>

                        <h6>Mukim:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            {{ this.property_mukim ? this.property_mukim : 'Not Available' }}
                        </div>
                        
                        <h6>District:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            {{ this.property_district ? this.property_district : 'Not Available' }}
                        </div>
                        
                        <h6>City:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            {{ this.city ? this.city : 'Not Available' }}
                        </div>
                        
                        <h6>Postcode:</h6>
                        <div style="padding-bottom: 20px; font-size: 0.9rem">
                            {{ this.property_postcode ? this.property_postcode : 'Not Available' }}
                        </div>
                        
                        <h6>State:</h6>
                        <div style="padding-bottom: 30px; font-size: 0.9rem">
                            {{ this.state ? this.state : 'Not Available' }}
                        </div>
                        
                        <iframe
                            width="100%"
                            height="450"
                            frameborder="0" 
                            style="border:0"
                            :src="mapSrc"
                            allowfullscreen>
                        </iframe>
                    </div>

                    <div v-if="this.tab4" class="p-3">
                        <h6>Share Link:</h6>
                        <div class="d-flex">
                            <div class="p-2 rounded" style="white-space: nowrap; padding-bottom: 20px; font-size: 0.9rem; border: #CCC solid 1px; max-width: 300px; height: 40px; overflow: scroll;">
                                {{ 'https://landcartel.my/web/property-details?listing_id=' + this.$route.query.listing_id }}
                            </div>
                            <div class="ps-2">
                                <button @click="copyLink(this.$route.query.listing_id)" class="btn btn-tertiary py-2" style="width:180px; height: 40px; border: #CCC solid 1px; color:#414042" type="button">
                                    <div class="d-flex" style="justify-content: center;">
                                        <div>
                                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 3C15 2.44772 15.4477 2 16 2C19.3137 2 22 4.68629 22 8V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16C2 15.4477 2.44772 15 3 15C3.55228 15 4 15.4477 4 16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4C15.4477 4 15 3.55228 15 3Z" fill="#000000"/>
                                                <path d="M3.70663 12.7845L3.16104 12.2746L3.70664 12.7845C4.09784 12.3659 4.62287 11.8265 5.17057 11.3274C5.72852 10.8191 6.26942 10.3905 6.69641 10.1599C7.06268 9.96208 7.75042 9.84035 8.40045 9.84848C8.62464 9.85128 8.81365 9.86944 8.9559 9.89472C8.96038 10.5499 8.95447 11.7469 8.95145 12.2627C8.94709 13.0099 9.83876 13.398 10.3829 12.8878L14.9391 8.61636C15.2845 8.2926 15.2988 7.74908 14.971 7.4076L10.4132 2.65991C9.88293 2.10757 8.95 2.48291 8.95 3.24856V5.16793C8.5431 5.13738 8.0261 5.11437 7.47937 5.13009C6.5313 5.15734 5.30943 5.30257 4.4722 5.88397C4.36796 5.95636 4.26827 6.03539 4.17359 6.11781C2.49277 7.58092 2.11567 9.90795 1.8924 11.7685L1.87242 11.935C1.74795 12.9722 3.02541 13.5134 3.70663 12.7845ZM9.35701 11.7935L9.70204 12.1615L9.35701 11.7935C9.35715 11.7934 9.35729 11.7932 9.35744 11.7931L9.35701 11.7935Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round"/>
                                            </svg>
                                        </div>
                                        <div class="ps-2">
                                            Copy Link
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-2"></div>

            <!-- iplan -->
            <div class="card p-4">
                <div class="d-flex" style="justify-content: space-between;">
                    <h5>
                        IPlan
                        <svg style="cursor: pointer" data-bs-toggle="modal" data-bs-target="#iplan" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="20px" height="20px"><path fill="#2196f3" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"/><path fill="#fff" d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"/></svg>
                    </h5>
                </div>
                <div class="py-1"></div>
                <div style="border-bottom: 1px solid #CCCCCC"></div>
                <div class="py-2"></div>
                <div class="">
                    <iframe
                        src="https://iplan.planmalaysia.gov.my/public/geoportal"
                        width="100%"
                        height="700"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>        
        </div>

        <!-- RIGHT SIDE (AGENT DETAIL/ PROPERTY SEARCH)-->
        <div class="px-1 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5">
            <div class="d-flex bg-white p-3 rounded" style="flex-direction:column;">

                <div class="d-flex" style="flex-direction: column;">

                    <div class="d-flex ps-2" style="flex-direction: row;">
                        <div class="d-flex">
                            <img id="profile-tour" alt="" :src="this.agent_image" data-intro="Profile image" style="border-radius: 50%; width:100px; height:100px;"/>
                        </div>

                        <div class="d-flex" style="align-items: center">
                            <!-- <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" fill="#354052" class="bi bi-person" viewBox="0 0 16 16">
                                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                                </svg>
                            </div> -->
                            <div class="ps-2" style="margin-top: 5px; font-size:1.1rem; color:#6C7592;">{{ this.agent_name }}</div>
                        </div>
                    </div>

                    <div class="d-flex">
                        <!-- <div style="margin-top: 4px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="28px" height="28px" fill="currentColor" class="bi bi-file-earmark-check-fill" viewBox="0 0 16 16">
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m1.354 4.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708"/>
                            </svg>
                        </div> -->
                        <div class="p-2" style="font-size:0.8rem; color:#6C7592;">{{ this.agent_bio }}</div>
                    </div>

                </div>

                <div>
                    <div class="py-2">
                        <input class="form-control py-2" id="total_sales" type="text" placeholder="Name" v-model="this.name" />
                    </div>
                    <div class="py-2">
                        <input class="form-control py-2" id="total_sales" type="text" placeholder="Phone" v-model="this.phone"/>
                    </div>
                    <div class="py-2">
                        <input class="form-control py-2" id="total_sales" type="text" placeholder="Email" v-model="this.email"/>
                    </div>
                    <div class="py-2">
                        <textarea class="form-control" type="text" placeholder="Your Message" v-model="this.detail"></textarea>
                    </div>
                    <div class="py-1"></div>
                    <div style="border-bottom: 1px solid #CCCCCC"></div>
                    <div class="py-1"></div>
                    <div class="py-1 d-flex" style="font-size:1.2rem">
                        <div class="p-1"><input id="terms" type="checkbox" name="terms" value="on" style="width:15px;height:15px;" v-model="this.agreetnc" /></div>
                        <p class="p-1" style="font-size: 0.9rem">I agree with your <span data-bs-toggle="modal" data-bs-target="#tnc" style="color:#C89835;cursor:pointer;">Terms & Conditions</span></p>
                    </div>
                    <div class="d-flex">
                        <div class="px-1" style="width:50%">
                            <button class="btn btn-block py-2" style="width:100%;background-color: #414042;color:#fefefe" type="button" @click="sendRequest()">Send Email</button>
                        </div>
                        <div class="px-1" style="width:50%">
                            <button @click="redirectToWhatsapp()"
                            class="btn btn-block py-2" style="width:100%;background-color: #C89835;color:#fefefe" type="button">Whatsapp</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Trigger Button and Hidden Checkbox -->
    <input type="checkbox" id="popupToggle">
    <!-- Popup Structure -->
    <div class="overlay">
        <div class="popup">
            <label for="popupToggle" class="close-btn position-close-btn" >
                <span style="font-weight: 600; color: #FEFEFE;">X</span>
            </label>
            <div class="carousel-container">
                <transition-group name="fade" tag="div">
                    <div v-for="(image, imageIndex) in this.image_property" :key="imageIndex" style="width: 100%; height:100%">
                        <img 
                            :src="image.image" 
                            v-show="currentIndexes1[0] === imageIndex" 
                            class="carousel-image" 
                            style="height: 100%;" 
                        />
                    </div>
                </transition-group>

                <button @click="prev1(0)" class="carousel-control left"><span style="z-index: 1000 !important;">&lt;</span></button>
                <button @click="next1(0)" class="carousel-control right"><span style="z-index: 1000 !important;">&gt;</span></button>

                <div class="carousel-indicators">
                    <span
                        v-for="(image, imageIndex) in this.image_property"
                        :key="imageIndex"
                        :class="{ active: currentIndexes1[0] === imageIndex }"
                        @click="setIndex1(0, imageIndex)"
                    ></span>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade modal-bookmark" id="iplan" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">How to use IPlan</h5>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;" class="ps-3 pe-3">
                    <div class="modal-body row" style="justify-content: space-between">
                        <div class="carousel-container">
                            <transition-group name="fade" tag="div">
                                <div v-for="(image, imageIndex) in images" :key="imageIndex" style="width: 100%; height:100%">
                                    <img 
                                        :src="image.image" 
                                        v-show="currentIndexes[0] === imageIndex" 
                                        class="carousel-image" 
                                        style="height: 100%; border-radius: 10px;" 
                                    />
                                </div>
                            </transition-group>

                            <button @click="prev(0)" class="carousel-control left">&lt;</button>
                            <button @click="next(0)" class="carousel-control right">&gt;</button>

                            <div class="carousel-indicators">
                                <span
                                    v-for="(image, imageIndex) in images"
                                    :key="imageIndex"
                                    :class="{ active: currentIndexes[0] === imageIndex }"
                                    @click="setIndex(0, imageIndex)"
                                ></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row p-3">
                    <div class="col-12 d-flex" style="justify-content: flex-end">
                        <div>
                            <button data-bs-dismiss="modal" class="btn btn-color" type="button" style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                I Understand
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {
    listPropertyDetails,
    listLandDetails,
    sendAgentEnquiry
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import iplan1 from '../../assets/images/step1.png';
import iplan2 from '../../assets/images/step2.png';
import iplan3 from '../../assets/images/step3.png';
import iplan4 from '../../assets/images/step4.png';
import iplan5 from '../../assets/images/step5.png';
import iplan6 from '../../assets/images/step6.png';
import iplan7 from '../../assets/images/step7.png';
import iplan8 from '../../assets/images/step8.png';

export default {
    components: {
    },

    data() {
        return {
            user_id: null,
            agreetnc: false,
            name: null,
            email: null,
            phone: null,
            detail: null,
            title: null,
            currentIndexes: [0],
            currentIndexes1: [0],
            images: [
                {"image": iplan1},
                {"image": iplan2},
                {"image": iplan3},
                {"image": iplan4},
                {"image": iplan5},
                {"image": iplan6},
                {"image": iplan7},
                {"image": iplan8},
            ],
            land_title: null,
            size_of_land: null,
            size_of_land_unit: null,
            land_topography: null,
            land_category: null,
            land_sales_type: null,
            land_price: null,
            price_land_size_amount: null,
            price_land_size_unit: null,
            city: null,
            land_geolocation: null,
            property_address: null,
            property_district: null,
            property_mukim: null,
            property_postcode: null,
            state: null,
            image_property: [],
            land_status: null,
            land_type: null,
            land_zoning: null,
            Listing: null,
            agent_image: null,
            agent_name: null,
            agent_bio: null,
            description: null,
            tab1: true,
            tab2: false,
            tab3: false,
            activeTab: "dashboard",
            type_listing: null,
            latitude_longitude: null,
            image_popup: null,
        };
    },

    computed: {
        mapSrc() {
            return `https://www.google.com/maps?q=${ this.land_geolocation }&hl=es;z=14&output=embed`;
        },
    },

    mounted() {
        if (localStorage.getItem("type_property") == 'Land') {
            this.LandListDetail();
            this.type_listing = 'Land';

        } else if (localStorage.getItem("type_property") == "Property") {
            this.PropertyListDetail();
            this.type_listing = 'Property';
        }
    },

    created() {
    },

    methods: {
        redirectToWhatsapp() {
            window.location.href = "https://api.whatsapp.com/send?phone=6" + this.agent_phone + "&text=Im%20interested%20with%20this%20land%3A%20https%3A%2F%2Flandcartel.my%2Fweb%2Fproperty-details%3Flisting_id%3D" + this.land_id;
        },

        copyLink(id) {
            const link = `https://landcartel.my/web/property-details?listing_id=${id}`;
            
            // Copy the link to the clipboard
            navigator.clipboard.writeText(link)
            .then(() => {
                this.notificationSuccess("Link copied!");
            })
            .catch(err => {
                this.notificationError("Something went wrong!");
            });
        },

        async sendRequest() {
            if (this.agreetnc == false) {
                this.notificationError("Please agree with term and condition");
            } else {
                this.title = this.land_title;

                try {
                    const dataPass = {
                        name : this.name,
                        email : this.email,
                        phone : this.phone,
                        detail : this.detail,
                        title : this.title + ' https://landcartel.my/web/property-details?listing_id=' + this.$route.query.listing_id,
                        user_id : this.user_id,
                    };
                    await sendAgentEnquiry(dataPass).then((response) => {
                        if (response.data.success == true) {
                            this.notificationSuccess("Successfully Sent Your Message");
                        } else {
                            this.notificationError(response.data.message);
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
            this.clearfield();
        },

        clearfield() {
            this.name = null;
            this.email = null;
            this.phone = null;
            this.detail = null;
            this.title = null;
            this.agreetnc =false;
        },

        prev(landIndex) {
            const totalImages = this.images.length;
            this.currentIndexes[landIndex] = (this.currentIndexes[landIndex] - 1 + totalImages) % totalImages;
        },
        next(landIndex) {
            const totalImages = this.images.length;
            this.currentIndexes[landIndex] = (this.currentIndexes[landIndex] + 1) % totalImages;
        },
        setIndex(landIndex, imageIndex) {
            this.currentIndexes[landIndex] = imageIndex;
        },

        prev1(landIndex) {
            const totalImages = this.image_property.length;
            this.currentIndexes1[landIndex] = (this.currentIndexes1[landIndex] - 1 + totalImages) % totalImages;
        },
        next1(landIndex) {
            const totalImages = this.image_property.length;
            this.currentIndexes1[landIndex] = (this.currentIndexes1[landIndex] + 1) % totalImages;
        },
        setIndex1(landIndex, imageIndex) {
            this.currentIndexes1[landIndex] = imageIndex;
        },

        capitalizeFirstLetter(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        },

        convertType(text) {
            const arr = {
                "sale": "For Sale",
                "rent": "For Rent",
                "lease": "For Lease"
            };
            return arr[text] || text;
        },

        convertStatus(text) {
            const arr = {
                "freehold_open": "Freehold Open",
                "freehold_mreserved": "Freehold Malay Reserved / MCL",
                "freehold_clnt": "CL / NT (Sabah/Sarawak)",
                "leasehold_open": "Leasehold Open",
                "leasehold_mreserved": "Leasehold Malay Reserved / MCL",
                "easehold_clnt": "Leasehold CL / NT (Sabah/Sarawak)",
            };
            
            return arr[text] || text;
        },
        convertCategory(text) {
            const arr = {
                "vacant_land" : "Vacant Land",
                "land_bungalow" : "Land + Bungalow / Semi D Land",
                "land_agriculture" : "Land + Agriculture status",
                "land_building" : "Land + Building status",
                "land_industry" : "Land + Industry status",
                "land_commercial" : "Land + Commercial status",
                "land_plantation" : "Land + Plantation / Orchard",
                "land_house" : "Land + House",
                "land_shoplot" : "Land + Shoplot / Supermarket",
                "land_petrol" : "Land + Petrol Station",
                "land_hotel" : "Land + Building / Hotel / Resort / Villa",
                "land_warehouse" : "Land + Warehouse / Factory",
                "land_do" : "Land + DO / KM",
                "land_mining" : "Land + Mining / Sand / Quary",
                "land_island" : "Land + Island",
                "land_other" : "(All) Land + Other / Mix",
            };
            
            return arr[text] || text;
        },
        convertSalesType(text) {
            const arr = {
                "sold": "Subsale",
                "new": "New Project",
            };
            
            return arr[text] || text;
        },



        setActiveTab(cat) {
            this.activeTab =  cat;

            if (this.activeTab == 'description') {
                this.tab1 = true;
                this.tab2 = false;
                this.tab3 = false;
                this.tab4 = false;

            } else if (this.activeTab == 'details') {
                this.tab1 = false;
                this.tab2 = true;
                this.tab3 = false;
                this.tab4 = false;

            } else if (this.activeTab == 'location') {
                this.tab1 = false;
                this.tab2 = false;
                this.tab3 = true;
                this.tab4 = false;

            } else if (this.activeTab == 'share') {
                this.tab1 = false;
                this.tab2 = false;
                this.tab3 = false;
                this.tab4 = true;
            }
        },
        
        async LandListDetail() {
            const dataPass = {
                id_listing: this.$route.query.listing_id
            };

            try {
                await listLandDetails(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.Listing = response.data.data;
                        // console.log(this.Listing);
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async PropertyListDetail() {
            const dataPass = {
                id_listing: this.$route.query.listing_id
            };

            try {
                await listPropertyDetails(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.Listing = response.data.data;

                        this.user_id = this.Listing.agent.id;
                        this.land_title = this.Listing.land_title;
                        this.land_topography = this.Listing.land_topography;
                        this.land_category = this.Listing.land_category;
                        this.land_sales_type = this.Listing.land_sales_type;
                        this.land_price = this.Listing.land_price;
                        this.price_land_size_amount = this.Listing.price_land_size_amount;
                        this.price_land_size_unit = this.Listing.price_land_size_unit;
                        this.city = this.Listing.city;
                        this.land_geolocation = this.Listing.land_geolocation;
                        this.property_address = this.Listing.property_address;
                        this.property_district = this.Listing.property_district;
                        this.property_mukim = this.Listing.property_mukim;
                        this.property_postcode = this.Listing.property_postcode;
                        this.state = this.Listing.state;
                        this.land_id = this.Listing.id;
                        this.land_status = this.Listing.land_status;
                        this.land_type = this.Listing.land_type;
                        this.size_of_land = this.Listing.size_of_land;
                        this.size_of_land_unit = this.Listing.size_of_land_unit;
                        this.land_zoning = this.Listing.land_zoning;
                        this.agent_image = this.Listing.agent.detail.image;
                        this.agent_name = this.Listing.agent.detail.name;
                        this.agent_bio = this.Listing.agent.detail.bio;
                        this.image_property = this.Listing.images;
                        this.description = this.Listing.description;

                        this.agent_phone = this.Listing.agent.detail.phone;
                        this.soldListing = this.Listing.sold;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        // uiux
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
    },
};
</script>
