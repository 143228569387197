<style scoped>
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
    <div class="padding-container">

        <div class="card rounded p-4">
            <h4>Registration</h4>
            <p>Fill in all the required details below to proceed</p>
            <div class="form-group">
                <label class="col-form-label">Role</label>
                <select class="form-select form-control-primary" name="select" required v-model="this.newRole">
                    <option value="" disabled selected required > Select User </option>
                    <option value="1">ADMIN</option>
                    <option value="2">REA / REN / PEA / PV / VE / PM</option>
                    <option value="4">LAND REPS / BROKER</option>
                    <option value="3">INDIVIDUAL</option>
                </select>
            </div>
            <div class="form-group">
                <label class="col-form-label">Name</label>
                <input class="form-control" v-model="this.newName" type="text" required="" placeholder="Ahmad"/>
            </div>
            <div class="form-group">
                <label class="col-form-label">Username</label>
                <input class="form-control" v-model="this.newUsername" type="text" required="" placeholder="ahmad1131"/>
            </div>
            <div class="form-group">
                <label class="col-form-label">Phone</label>
                <input class="form-control" v-model="this.newPhone" type="text" required="" placeholder="0146678941"/>
            </div>
            <div class="form-group">
                <label class="col-form-label">Email</label>
                <input class="form-control" v-model="this.newEmail" type="text" required="" placeholder="ahmad@landcartel.my"/>
            </div>
            <div class="form-group">
                <label class="col-form-label">Password</label>
                <input class="form-control" v-model="this.newPassword" type="password" required="" placeholder="********"/>
            </div>
            <div class="form-group">
                <label class="col-form-label">Confirm Password</label>
                <input class="form-control" v-model="this.newConfirmPassword" type="password" required="" placeholder="********"/>
            </div>
            <div class="form-group mb-0">
                <div class="text-end mt-3">
                    <button class="btn btn-primary btn-block w-100" type="button" @click="registerUser()">Confirm</button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    userAdd
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Registration",

    data() {
        return {
            newRole: "",
            newName: null,
            newPhone: null,
            newEmail: null,
            newUsername: null,
            newPassword: null,
            newConfirmPassword: null,
        };
    },

    computed: {},
    
    beforeMount() {},
    
    mounted() {},

    created() {},

    methods: {
        // ajax
        async registerUser() {
            const dataPass = {
                name: this.newName,
                email: this.newEmail,
                phone: this.newPhone,
                username: this.newUsername,
                password: this.newPassword,
                cpassword: this.newConfirmPassword,
                role: this.newRole,
            };
            try {
                await userAdd(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.notificationSuccess("Success");
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
            this.clearForm();
        },

        clearForm() {
            this.newRole = "";
            this.newName = null;
            this.newPhone = null;
            this.newEmail = null;
            this.newUsername = null;
            this.newPassword = null;
            this.newConfirmPassword = null;
        },

        // uiux
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
    },
};
</script>
