export function menuItems(role) {
    return {
        data: [
            //
            //
            //
            // Dashboard
            {
                headTitle1: "General",
                headTitle2: "Dashboard Menu",
                type: "headtitle",
            },
            {
                title: "Dashboard",
                path: "/dashboard/stats",
                icon: "stroke-home",
                iconf: "fill-home",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            {
                headTitle1: "Listing",
                headTitle2: "listing menu",
                type: "headtitle",
            },
            //
            //
            //
            //
            // LISTING MENU
            ...(role == 2 || role == 3 || role == 4 ? [
            {
                title: "My Listing",
                path: "/setting/listing",
                icon: "stroke-blog",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            }
            ] : []),

            ...(role == 1 ? [
            {
                title: "Corporate Listing",
                path: "/setting/corporate-listing",
                icon: "stroke-blog",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            }
            ] : []),
            //
            //
            //
            //
            // SETTING MENU
            {
                headTitle1: "Setting",
                headTitle2: "setting menu",
                type: "headtitle",
            },
            ...(role == 1 ? [
            {
                title: "Exchange Rates",
                path: "/setting/coins",
                icon: "stroke-sample-page",
                iconf: "fill-user",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            {
                title: "Company",
                path: "/setting/company",
                icon: "stroke-social",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            {
                title: "Packages",
                path: "/setting/packages",
                icon: "stroke-calendar",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            {
                title: "Coupons",
                path: "/setting/coupons",
                icon: "stroke-calendar",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            {
                title: "Approval Listing",
                path: "/setting/approval",
                icon: "stroke-blog",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            {
                title: "Manage User",
                path: "/setting/usermanagement",
                icon: "stroke-user",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            {
                title: "Registration",
                path: "/setting/registration",
                icon: "stroke-user",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            ] : []),
            ...(role == 2 || role == 3 || role == 4 ? [
            {
                title: "My Subscription",
                path: "/user/subscription",
                icon: "stroke-internationalization",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            },
            {
                title: "My Balance",
                path: "/user/balance",
                icon: "stroke-animation",
                iconf: "fill-form",
                type: "link",
                badgeType: "light-primary",
                active: false,
            }
            ] : []),
            // {
            //     title: "Token",
            //     path: "/setting/token",
            //     icon: "stroke-email",
            //     iconf: "fill-form",
            //     type: "link",
            //     badgeType: "light-primary",
            //     active: false,
            // },
            //
            //
            //
            //
            // Reporting
            // {
            //     headTitle1: "REPORTING",
            //     headTitle2: "reporting menu",
            //     type: "headtitle",
            // },
            // {
            //     title: "User Subscribe",
            //     path: "/report/subscribe",
            //     icon: "stroke-sample-page",
            //     iconf: "fill-file",
            //     type: "link",
            //     active: false,
            // },
            // {
            //     title: "Listing Performance",
            //     path: "/report/rlisting",
            //     icon: "stroke-blog",
            //     iconf: "fill-file",
            //     type: "link",
            //     active: false,
            // },
            // {
            //     title: "Metric Performance",
            //     path: "/report/metric",
            //     icon: "stroke-internationalization",
            //     iconf: "fill-file",
            //     type: "link",
            //     active: false,
            // },
            //
            //
            //
            //
            // Spacer
            {
                headTitle1: "",
                headTitle2: "",
                type: "headtitle",
            },
            {
                headTitle1: "",
                headTitle2: "",
                type: "headtitle",
            },
        ],
    };
}
