<template>
	<div class="container-fluid pt-2">
		<div class="row">
			<div class="col-xl-6 set-col-12 box-col-12">
				<div class="card" style="cursor: pointer" @click="redirect('Perspiciatis-unde-omnis5')">
					<div class="blog-box blog-shadow"><img class="img-fluid bg-img-cover" src="@/assets/images/blog/blog.jpg" alt="">
					<div class="blog-details">
						<p>25 July 2025</p>
						<h4>Accusamus et iusto odio dignissimos ducimus qui blanditiis.</h4>
						<ul class="blog-social">
						<li><i class="icofont icofont-user"></i>Mark Jecno</li>
						<!-- <li><i class="icofont icofont-thumbs-up"></i>02 Hits</li> -->
						<!-- <li><i class="icofont icofont-ui-chat"></i>598 Comments</li> -->
						</ul>
					</div>
					</div>
				</div>
			</div>

			<div class="col-xl-6 set-col-12 box-col-12">
				<div class="card" style="cursor: pointer" @click="redirect('Perspiciatis-unde-omnis6')">
				<div class="blog-box blog-list row">
					<div class="col-sm-5"><img class="img-fluid sm-100-w" src="@/assets/images/blog/blog-2.jpg" alt=""></div>
					<div class="col-sm-7">
					<div class="blog-details">
						<div class="blog-date"><span>02</span> January 2025</div>
						<h6>Perspiciatis unde omnis iste natus</h6>
						<div class="blog-bottom-content">
						<ul class="blog-social">
							<li>by: Admin</li>
							<!-- <li>0 Hits</li> -->
						</ul>
						<hr>
						<p class="mt-0">inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit.</p>
						</div>
					</div>
					</div>
				</div>
				</div>
				<div class="card" style="cursor: pointer" @click="redirect('Perspiciatis-unde-omnis7')">
				<div class="blog-box blog-list row">
					<div class="col-sm-5"><img class="img-fluid sm-100-w" src="@/assets/images/blog/blog-3.jpg" alt=""></div>
					<div class="col-sm-7">
					<div class="blog-details">
						<div class="blog-date"><span>03</span> January 2025</div>
						<h6>Perspiciatis unde omnis iste natus</h6>
						<div class="blog-bottom-content">
						<ul class="blog-social">
							<li>by: Admin</li>
							<!-- <li>02 Hits</li> -->
						</ul>
						<hr>
						<p class="mt-0">inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit.</p>
						</div>
					</div>
					</div>
				</div>
				</div>
			</div>

			<div class="col-md-6 col-xxl-3 box-col-6" v-for="(item,index) in items" :key="index">
				<div class="card" style="cursor: pointer" @click="redirect(item.link)">
				<div class="blog-box blog-grid text-center">
					<div class="blog-wrraper"><img class="img-fluid top-radius-blog" :src="getImageUrl(item.image)" alt=""></div>
					<div class="blog-details-main">
					<ul class="blog-social">
						<li>{{ item.title }}</li>
						<li>9 January 2025</li>
						<li>by: Admin</li>
						<!-- <li>0 Hits</li> -->
					</ul>
					<hr>
					<h6 class="blog-bottom-details">Perspiciatis unde omnis iste natus error sit.Dummy text</h6>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
    components:{
		
    },
    data(){
      	return {
			items:[
				{id:1, link:"Perspiciatis-unde-omnis1", title:"Perspiciatis unde omnis1", desc:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.", image:"blog-5.jpg"},
				{id:1, link:"Perspiciatis-unde-omnis2", title:"Perspiciatis unde omnis2", desc:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.", image:"blog-6.jpg"},
				{id:1, link:"Perspiciatis-unde-omnis3", title:"Perspiciatis unde omnis3", desc:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.", image:"blog-5.jpg"},
				{id:1, link:"Perspiciatis-unde-omnis4", title:"Perspiciatis unde omnis4", desc:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.", image:"blog-6.jpg"},
			]
    	}
	},
    methods: {
		redirect(title) {
            this.$router.push("/web/blog/" + title);
		},
		getImageUrl(path) {
			return require("../../../assets/images/blog/" + path);
		},
    },
}
</script>