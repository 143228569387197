
<style scoped>
.round {
    position: relative;
}

.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 20%;
    cursor: pointer;
    height: 24px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 24px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 5px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
    border-color: #66bb6a;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  overflow:hidden; 
  border: 1px solid #888;
  width: 40%; /* Could be more or less, depending on screen size */
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.image-container {
    position: relative;
    display: inline-block; /* or use width: 100%; to make it full width */
}

.img-fluid {
    display: block;
}

.centered-content {
    position: absolute;
    top: 40%;
    left: 50%;
    height: 20vh;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 60%;
}

.logo {
    display: block;
    margin: 0 auto;
}

.centered-text {
    width:100%;
    background-color: rgba(255, 255, 255); /* Optional: to make the background semi-transparent */
    padding: 15px;
    border-radius: 15px; /* Optional: to give the div rounded corners */
    margin-top: 10px; /* Adjust this value to control the spacing between the logo and the text */
}

.select-home {
  width:100%;
  border: 1px solid #BBBBBB;
  color:#BBBBBB;
}

.select-type {
  width:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-type {
  background:#191E32;
  color:#FEFEFE;
  width:100%;
}


.carousel-container {
position: relative;
width: 100%;
}

.carousel-image {
width: 100%;
object-fit: cover;
}

.carousel-control {
position: absolute;
top: 50%;
transform: translateY(-50%);
background-color: rgba(0, 0, 0, 0.5);
color: white;
border: none;
padding: 10px;
cursor: pointer;
}

.carousel-control.left {
left: 10px;
}

.carousel-control.right {
right: 10px;
}

.carousel-indicators {
display: flex;
justify-content: center;
margin-top: 10px;
}

.carousel-indicators span {
display: inline-block;
width: 10px;
height: 10px;
margin: 0 5px;
background-color: grey;
border-radius: 50%;
cursor: pointer;
}

.carousel-indicators .active {
background-color: black;
}

.land-title-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.toggle-button {
    display: none;
}

.sidebar1 {
    position: fixed;
    top: 76px;
    height: 100%;
    right: -320px;
    /* width: 280px; */
    background-color: #f7f8f9;
    z-index: 1001;
    transition: right 0.3s ease;
    display: flex;
    /* flex-direction: column; */
}

.sidebar1.is-open1 {
    right: 0px !important;
    /* transition: right 0.3s ease; */
}

@media (max-width: 992px) {
    .desktop-filter {
        display: none !important;
    }

    .toggle-button {
        display: block;
        position: fixed;
        top: 85px;
        right: 0px;
    }

    .sidebar1 {
        /* right: -280px; */
        background-color: #FFF;
    }
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
<div class="card hovercard text-center">
<!-- <div class="cardheader" style="    background-size: cover;
background-position: 10%;
height: 470px;" :style="{backgroundImage: 'url('+ require('@/assets/images/other-images/bg-profile.png') +')'}"></div> -->
    <div class="user-image">
        <div class="avatar">
            <img id="profile-tour" style="border-radius:50%; width: 200px; height: 200px;" :src="this.profile_image_header" />

            <!-- <img id="profile-tour" alt="" src="../../../assets/images/user/7.jpg" data-intro="This is Profile image"> -->
        </div>
        <!-- <div class="icon-wrapper"><i id="update-profile-tour" class="icofont icofont-pencil-alt-5" data-intro="Change Profile image here"></i></div> -->
    </div>
    <div class="info">
        <div class="row" data-intro="This is the your details" id="info-bar-tour">

            <div class="col-sm-12">
                <div class="user-designation px-3">
                    <div class="title" style="font-size: 20px;">{{ this.dataReceived.name }}</div>
                </div>
            </div>
            <div class="col-md-3 p-2">
                <div style="text-align: center;" class="p-3 ttl-info text-start">
                    <h6><i class="fa fa-envelope"></i>   Email</h6><span>{{ this.dataReceived.email }}</span>
                </div>
            </div>
            <div class="col-md-3 p-2">
                <div style="text-align: center;" class="p-3 ttl-info text-start ttl-sm-mb-0">
                    <h6><i class="fa fa-calendar"></i>REND ID</h6><span>{{ this.dataReceived.rendid? this.dataReceived.rendid : 'Not Available' }}</span>
                </div>
            </div>
            <div class="col-md-3 p-2">
                <div style="text-align: center;" class="p-3 ttl-info text-start ttl-xs-mt">
                    <h6><i class="fa fa-phone"></i>   Contact Us</h6><span>{{ this.dataReceived.phone }}</span>
                </div>
            </div>
            <div class="col-md-3 p-2">
                <div style="text-align: center;" class="p-3 ttl-info text-start ttl-sm-mb-0">
                    <h6><i class="fa fa-location-arrow"></i>   Location</h6><span> {{ this.dataReceived.city + ' ' + this.dataReceived.state }}</span>
                </div>
            </div>
        </div>
        <hr>
        <div class="follow">
            <div class="row"> 
                <div class="col-6 p-3 pb-4" style="text-align: center; width: 100%;">
                    <div class="follow-num counter">{{ this.dataReceived.userBio }}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="d-flex" style="justify-content: space-between">
    <!-- LEFT SIDE (HOUSE OVERVIEW)-->
    <div class="padding-container w-100" v-if="this.tableLoader == false">
        <div v-if="this.emptyListing">
            <!-- LISTING -->
            <div class="row">
                <div style="cursor: pointer;" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4" v-for="(item, index) in this.Listing" :key="index">
                    <div class="bg-white rounded" style="width: 100%; border: 1px solid #bbbbbb;" v-if="item.public == 1">
                        
                        <div style="height: 208px; width:100%;">
                            <div v-if="item.video != ''">
                                <video ref="videoElement" width="100%" height="208px" controls autoplay muted>
                                    <source :src="item.video" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>

                            <div v-else>
                                <div class="carousel-container">
                                    <transition-group name="fade" tag="div">
                                        <div v-for="(image, imageIndex) in item.images" :key="imageIndex" style="width: 100%;">
                                            <img :src="image.image" v-show="currentIndexes[index] === imageIndex" class="carousel-image" 
                                            style="height: 208px; border-top-left-radius: 10px; border-top-right-radius: 10px" />
                                        </div>
                                    </transition-group>

                                    <button @click="prev(index)" class="carousel-control left">&lt;</button>
                                    <button @click="next(index)" class="carousel-control right">&gt;</button>

                                    <div class="carousel-indicators">
                                        <span
                                            v-for="(image, imageIndex) in item.images"
                                            :key="imageIndex"
                                            :class="{ active: currentIndexes[index] === imageIndex }"
                                            @click="setIndex(index, imageIndex)"
                                        ></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- title -->
                        <div class="p-2 d-flex" style="justify-content: space-between; flex-direction: column" @click="redirectDetails(item.id)">
                            <div class="land-title-name" style="color:#2F2F3B; font-size:1rem; font-weight:400; max-width: 100%; height: 48px; overflow-wrap: break-word;">{{ item.land_title }}</div>
                            <div class="row">
                                <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                    <div style="color:#2F2F3B;font-size:1.2rem;font-weight:450">
                                        RM {{ parseFloat(item.land_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
                                    </div>
                                    <div v-if="this.loginAccess!='3' && this.loginAccess != null" style="color: #6C7592; font-weight: 300; font-size:0.7rem;">Potential Commission: RM {{ parseFloat(item.land_price * 3 / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</div>
                                    <div style="color: #6C7592; font-weight: 300; font-size:0.7rem;">{{ item.city }} {{ item.state }}</div>
                                </div>
                                <!-- <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <div v-if="this.loginAccess!='3' && this.loginAccess != null">
                                        <img style="width: 50px;" :src="require('@/assets/images/commission.png')" />
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <!-- detail -->
                        <div class="d-flex" style="border: 1px solid rgb(187, 187, 187,0.5)" @click="redirectDetails(item.id)">

                            <div class="p-2" align="center" style="width:33%">
                                <div style="height:35%">
                                    <svg width="22px" height="22px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 6H12.01M9 20L3 17V4L5 5M9 20L15 17M9 20V14M15 17L21 20V7L19 6M15 17V14M15 6.2C15 7.96731 13.5 9.4 12 11C10.5 9.4 9 7.96731 9 6.2C9 4.43269 10.3431 3 12 3C13.6569 3 15 4.43269 15 6.2Z" stroke="#6C7592" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div style="height:65%">
                                    <div class="d-flex" style="width: 100%; justify-content: center; flex-direction: column">
                                        <div style="text-align: center;">
                                            <h6 class="m-0" style="color: #6C7592; font-size:0.8rem;">{{ convertLandStatus(item.land_status) }}</h6>
                                        </div>
                                        <!-- <div style="font-size:0.7rem; font-weight: 300;">Land Zoning</div> -->
                                    </div>
                                </div>
                            </div>
                            
                            <div class="p-2" align="center" v-if="this.typeSearch == 'Property'" style="width:34%; border-right:1px solid rgb(187, 187, 187,0.5); border-left:1px solid rgb(187, 187, 187,0.5)">
                                <div style="height:35%">
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 14C2 10.2288 2 8.34315 3.17157 7.17157C4.34315 6 6.22876 6 10 6H14C17.7712 6 19.6569 6 20.8284 7.17157C22 8.34315 22 10.2288 22 14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14Z" stroke="#6C7592" stroke-width="1.5"/>
                                        <path opacity="0.5" d="M16 6C16 4.11438 16 3.17157 15.4142 2.58579C14.8284 2 13.8856 2 12 2C10.1144 2 9.17157 2 8.58579 2.58579C8 3.17157 8 4.11438 8 6" stroke="#6C7592" stroke-width="1.5"/>
                                        <path opacity="0.5" d="M12 17.3333C13.1046 17.3333 14 16.5871 14 15.6667C14 14.7462 13.1046 14 12 14C10.8954 14 10 13.2538 10 12.3333C10 11.4129 10.8954 10.6667 12 10.6667M12 17.3333C10.8954 17.3333 10 16.5871 10 15.6667M12 17.3333V18M12 10V10.6667M12 10.6667C13.1046 10.6667 14 11.4129 14 12.3333" stroke="#6C7592" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </div>
                                <div style="height:65%">
                                    <div class="d-flex" style="width: 100%; justify-content: center; flex-direction: column">
                                        <div style="text-align: center;">
                                            <h6 class="m-0" style="color: #6C7592; font-size:0.8rem;">{{ capitalizeFirstLetter(item.land_type) }}</h6>
                                        </div>
                                        <!-- <div style="font-size:0.7rem; font-weight: 300;">Land Status</div> -->
                                    </div>
                                </div>
                            </div>

                            <div class="p-2" align="center" style="width:33%;">
                                <div style="height:35%">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.96183 16.6582L4.96183 14.7007M8.87678 16.6582L8.87678 13.722M12.7917 16.6582L12.7917 14.7007M1.04687 12.7432L3.00435 12.7432M1.04687 8.8283L3.98309 8.8283M1.04687 4.91334L3.00435 4.91334M1.04687 15.6795L1.04687 1.97713C1.04687 1.71755 1.14999 1.4686 1.33354 1.28505C1.51709 1.1015 1.76604 0.998387 2.02561 0.998387L6.91931 0.998387C7.17888 0.998387 7.42783 1.1015 7.61138 1.28505C7.79493 1.4686 7.89804 1.71755 7.89804 1.97713L7.89804 8.82829C7.89804 9.08787 8.00116 9.33682 8.18471 9.52037C8.36826 9.70392 8.6172 9.80703 8.87678 9.80703L15.728 9.80703C15.9875 9.80703 16.2365 9.91015 16.42 10.0937C16.6036 10.2772 16.7067 10.5262 16.7067 10.7858L16.7067 15.6795C16.7067 15.939 16.6036 16.188 16.42 16.3715C16.2365 16.5551 15.9875 16.6582 15.728 16.6582L2.02561 16.6582C1.76604 16.6582 1.51709 16.5551 1.33354 16.3715C1.14999 16.188 1.04687 15.939 1.04687 15.6795Z" stroke="#7F869F" stroke-width="1.95748" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div style="height:65%">
                                    <div class="d-flex" style="width: 100%; justify-content: center; flex-direction: column">
                                        <div style="text-align: center;">
                                            <h6 class="m-0" style="color: #6C7592; font-size:0.8rem;">{{ convertAreaNumber(item.size_of_land, item.size_of_land_unit) }}</h6>
                                        </div>
                                        <div style="font-size:0.7rem; font-weight: 300;">{{ convertAreaString(item.size_of_land, item.size_of_land_unit) }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- agent -->
                        <div class="d-flex p-2">
                            <div style="width: 65%;" class="d-flex" @click="redirectDetails(item.id)">
                                <div>
                                    <img style="width:35px; height:35px; border-radius:20px;" :src="item.agent.detail.image || require('@/assets/images/user/avatar.png')" data-intro="This is Profile image"/>
                                </div>
                                <div>LC000001</div>

                                <div style="padding-left:10px; align-items: center;" class="d-flex">
                                    <div style="color:#6C7592; font-size:0.9rem;">{{ item.agent.detail.name }}</div>
                                    <!-- <div style="font-size: 0.7rem;">{{ item.agent.main.role == 3? "User" : "Landcartel Agent" }}</div> -->
                                </div>
                            </div>
                                
                            <div style="width:35%; align-items: center; align-content: center; justify-content: space-around" class="d-flex">
                                <div id="copy-link-animation">
                                    <div v-if="this.successCopyLink == item.id">
                                        <svg style="width:20px;height:20px;" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50" xml:space="preserve">
                                            <circle style="fill:#25AE88;" cx="25" cy="25" r="25"/>
                                            <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="38,15 22,33 12,25 "/>
                                        </svg>
                                    </div>
                                    <div v-if="this.errorCopyLink == item.id">
                                        <svg style="width:20px;height:20px;" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50" xml:space="preserve">
                                            <circle style="fill:#D75A4A;" cx="25" cy="25" r="25"/>
                                            <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" points="16,34 25,25 34,16"/>
                                            <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;" points="16,16 25,25 34,34"/>
                                        </svg>
                                    </div>
                                    <div v-if="this.loadCopyLink == item.id">
                                        <div v-if="this.resetCopyLink == item.id">
                                            <div class="loader-box" style="height: 16px; width: 20px; margin-top: -3px;">
                                                <div class="loader-3" style="width: 16px; height: 16px;"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <!-- lancartel popout and fade until finished  -->
                                        <svg @click="copyLink(item.id)" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 3C15 2.44772 15.4477 2 16 2C19.3137 2 22 4.68629 22 8V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16C2 15.4477 2.44772 15 3 15C3.55228 15 4 15.4477 4 16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4C15.4477 4 15 3.55228 15 3Z" fill="#000000"/>
                                            <path d="M3.70663 12.7845L3.16104 12.2746L3.70664 12.7845C4.09784 12.3659 4.62287 11.8265 5.17057 11.3274C5.72852 10.8191 6.26942 10.3905 6.69641 10.1599C7.06268 9.96208 7.75042 9.84035 8.40045 9.84848C8.62464 9.85128 8.81365 9.86944 8.9559 9.89472C8.96038 10.5499 8.95447 11.7469 8.95145 12.2627C8.94709 13.0099 9.83876 13.398 10.3829 12.8878L14.9391 8.61636C15.2845 8.2926 15.2988 7.74908 14.971 7.4076L10.4132 2.65991C9.88293 2.10757 8.95 2.48291 8.95 3.24856V5.16793C8.5431 5.13738 8.0261 5.11437 7.47937 5.13009C6.5313 5.15734 5.30943 5.30257 4.4722 5.88397C4.36796 5.95636 4.26827 6.03539 4.17359 6.11781C2.49277 7.58092 2.11567 9.90795 1.8924 11.7685L1.87242 11.935C1.74795 12.9722 3.02541 13.5134 3.70663 12.7845ZM9.35701 11.7935L9.70204 12.1615L9.35701 11.7935C9.35715 11.7934 9.35729 11.7932 9.35744 11.7931L9.35701 11.7935Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round"/>
                                        </svg>
                                    </div>
                                </div>
                                <div>
                                    <a :href="'https://api.whatsapp.com/send?phone=6' + item.agent.detail.phone + '&text=Im%20interested%20with%20this%20land%3A%20https%3A%2F%2Flandcartel.my%2Fweb%2Fproperty-details%3Flisting_id%3D' + item.id">
                                        <svg data-v-8d1dae30="" xmlns="http://www.w3.org/2000/svg" fill="#25D366" viewBox="0 0 448 512" width="18"><path data-v-8d1dae30="" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>
                                    </a>
                                </div>
                                <div>
                                    <a :href="'mailto:' + item.agent.email">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" stroke-width="50" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="py-2"></div>
                </div>
            </div>
        </div>
        <div v-else class="px-4 d-flex" style="flex-direction: column; align-items: center; width: 100%;">
            <div>
                <img style="border-radius: 5px; width: 300px;" alt="" src="@/assets/images/no_data_image.png" />
            </div>
            <div style="text-align: center;">
                <h6>No Listing found!</h6>
            </div>
        </div>
    </div>
    <div class="padding-container w-100" v-else>
        <div class="d-flex" style="flex-direction: column; justify-content: center;">
            <h6 class="mb-0 text-center">Please Wait...</h6>
            <div class="loader-box">
                <div class="loader-3"></div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    findUserDetails,
    getLandListing,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Checking",

    components: {
    },

    data() {
        return {
            dataReceived: [],
            profile_image_header: null,

			tableLoader: true,
            beenFilter: false,
            emptyListing: false,
            isOpen1: false,
            currentIndexes: [],
            selectedTab: 'property',
            typeSearch: 'Property',
            Listing: [],
            landListing: [],
            propertyListing: [],
        };
    },

    beforeMount() {
        this.checkUserDetails();
    },

    mounted() {
    },

    created() { },

    computed: { },

    methods: {

        convertAreaNumber(size_of_land, size_of_land_unit) {
            let area = 0.00;
            let size = 0.00;
            if (size_of_land_unit == "meter") {
                area = size_of_land / 4046.856;
            } else if (size_of_land_unit == "squarefoot") {
                area = size_of_land / 43560;
            } else if (size_of_land_unit == "hectar") {
                area = size_of_land * 2.471;
            } else if (size_of_land_unit == "acres") {
                area = size_of_land;
            }

            if (area > 1) {
                size = area;
            } else {
                size = area * 43560;
            }

            return parseFloat(size).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        },
        convertAreaString(size_of_land, size_of_land_unit) {

            let unitamount = '';
            let area = 0.00;
            if (size_of_land_unit == "meter") {
                area = size_of_land / 4046.856;
            } else if (size_of_land_unit == "squarefoot") {
                area = size_of_land / 43560;
            } else if (size_of_land_unit == "hectar") {
                area = size_of_land * 2.471;
            } else if (size_of_land_unit == "acres") {
                area = size_of_land;
            }

            if (area > 1) {
                unitamount = "Acres";
            } else {
                unitamount = "Sqft";
            }

            return this.capitalizeFirstLetter(unitamount);
        },
        convertLandStatus(status) {
            if (status == 'freehold_open') {
                return 'Freehold';
            } else if (status == 'freehold_mreserved') {
                return 'Freehold';
            } else if (status == 'freehold_clnt') {
                return 'Freehold';
            } else if (status == 'leasehold_open') {
                return 'Leasehold';
            } else if (status == 'leasehold_mreserved') {
                return 'Leasehold';
            } else if (status == 'leasehold_clnt') {
                return 'Leasehold';
            }
        },
        copyLink(id) {
            this.loadCopyLink = id;
            this.resetCopyLink = id;
            const link = `https://landcartel.my/web/property-details?listing_id=${id}`;
            
            // Copy the link to the clipboard
            navigator.clipboard.writeText(link)
            .then(() => {
                setTimeout(() => {
                    this.successCopyLink = id;
                    this.resetCopyLink = 0;
                }, 500);
                
                setTimeout(() => {
                    this.loadCopyLink = 0;
                    this.successCopyLink = 0;
                }, 2000);
            })
            .catch(err => {
                setTimeout(() => {
                    this.errorCopyLink = id;
                    this.resetCopyLink = 0;
                }, 500);
                
                setTimeout(() => {
                    this.loadCopyLink = 0;
                    this.errorCopyLink = 0;
                }, 2000);
            });
        },

        capitalizeFirstLetter(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        },

        prev(landIndex) {
            const totalImages = this.landListing[landIndex].images.length;
            this.currentIndexes[landIndex] = (this.currentIndexes[landIndex] - 1 + totalImages) % totalImages;
        },

        next(landIndex) {
            const totalImages = this.landListing[landIndex].images.length;
            this.currentIndexes[landIndex] = (this.currentIndexes[landIndex] + 1) % totalImages;
        },

        setIndex(landIndex, imageIndex) {
            this.currentIndexes[landIndex] = imageIndex;
        },

        setProfile(image_url) {
            if (image_url == null) {
                this.profile_image_header = require('@/assets/images/user/avatar.png');
            } else {
                this.profile_image_header = image_url;
            }
        },
        async checkUserDetails() {
            try {
                const dataPass = {
                    userName : this.$route.params.username,
                };
                await findUserDetails(dataPass).then((response) => {
                    if (response.data.success == true) {
                        // next("/error/404");
                        this.dataReceived.userBio = response.data.data.bio;
                        this.dataReceived.city = response.data.data.city;
                        this.dataReceived.state = response.data.data.state;
                        this.dataReceived.image = response.data.data.image;
                        this.dataReceived.name = response.data.data.name;
                        this.dataReceived.phone = response.data.data.phone;
                        this.dataReceived.email = response.data.data.email;
                        this.dataReceived.rendid = response.data.data.rendid;
                        this.dataReceived.userid = response.data.data.usr_main_id;
                        this.setProfile(this.dataReceived.image);
                        this.LandListing();

                    } else {
                        // this.$router.push("/error/404");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },


        async LandListing() {
            this.tableLoader = true;
            const dataPass = {
                search: this.listSearch,
                city: this.listCity,
                state: this.listState,
                type: this.listType,
                status: this.listStatus,
                category: this.listCategory,
                other: this.listOther,
                size_amount: this.listSizeAmount,
                size: this.listSize,
                price: this.listPrice,
                corporate: this.corporate,
                sold: "0",
                user_id_listing: this.dataReceived.userid,
            };

            if (this.listSearch != null ||
            this.listCity != null ||
            this.listState != "" ||
            this.listType != "" ||
            this.listStatus != "" ||
            this.listCategory != "" ||
            this.listOther != "" ||
            this.listSizeAmount != null ||
            this.listSize != "" ||
            this.listPrice != "") {
                this.beenFilter = true;
            }

            try {
                await getLandListing(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.landListing = response.data.data;
                        if (response.data.data.length === 0) {
                            this.emptyListing = false;
                        } else {
                            this.emptyListing = true;
                        }

                        this.Listing = this.landListing;
                        this.currentIndexes = this.landListing.map(() => 0);
                        this.tableLoader = false;

                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.tableLoader = false;
                console.log(error);
                this.notificationError("Something went wrong!");
            }
        },


        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
    },
};
</script>
